// frontend/src/App.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, } from 'react-router-dom'; 
import { useAuth0 } from '@auth0/auth0-react';
import LoginPage from './containers/LoginPage';
import ObsOverlay from './components/chatPages/obsOverlay';
import LivePage from './components/LivePage/LivePage';  
import BasicChatEditor from './basicChatPage/basicChatEditor';
import PopoutChatComponent from './components/LivePage/PopoutChatComponent'; 
import HomePage from './containers/HomePage';
import PopoutChat from './basicChatPage/components/basicPopoutChat';
import TwitchAuthCallback from './components/LivePage/TwitchAuthCallback';
import AccountPage from './accountPage/accountPage';
import CallbackPage from './containers/CallbackPage';
import { setAuthToken } from './services/api';
import AboutPage from './aboutPage/aboutPage';
import PrivacyPolicy from './containers/components/PrivacyPolicy';
import TermsOfService from './containers/components/TermsOfService';
import './styles/bootstrapstyle.css';


import { loginUser } from './redux/actions/authActions';


function App() {
  const { isAuthenticated, error, getAccessTokenSilently } = useAuth0(); // Add error handling for Auth0
  const { user } = useAuth0();
  const dispatch = useDispatch();


  
  

  useEffect(() => {
    const initializeLivePage = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          setAuthToken(token); // Attach token to Axios
  
          // Any further initialization
        } catch (error) {
          // console.error("Error initializing LivePage:", error);
        }
      }
    };
    
    initializeLivePage();
  }, [isAuthenticated, getAccessTokenSilently]);

  // Dispatch user info to Redux store on successful authentication
  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(loginUser(user));
    }
  }, [isAuthenticated, user, dispatch]);

  // Error handling for Auth0
  useEffect(() => {
    if (error) {
      // console.error("Auth0 session check failed:", error);
    }
  }, [error]);

  function ProtectedRoute({ children }) {
    const { isAuthenticated, isLoading } = useAuth0(); // Use isLoading to wait for Auth0 to resolve
  
    if (isLoading) return <div>Loading...</div>; // Show loading until Auth0 is ready
  
    if (!isAuthenticated) {
      return <LoginPage />; // Show LoginPage if not authenticated
    }
  
    return children;
  }


  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/live" element={<ProtectedRoute><LivePage /></ProtectedRoute>} />
      <Route path="/popout/chat" element={<PopoutChatComponent />} />
      <Route path="/obs/chat/:userID" element={<ObsOverlay />} />
      <Route path="/basicPopout" element={<PopoutChat />} />
      <Route path="/Create" element={<BasicChatEditor />} />
      <Route path="/twitch/callback" element={<TwitchAuthCallback />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/account" element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />

    </Routes>
  );
}

export default App;