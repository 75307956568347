// src/accountPage/accountPage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavBar from '../components/common/NavBar';
import Overview from './components/Overview';
import Profile from './components/Profile';
import Subscription from './components/Subscription';
import Help from './components/Help';
import { setAuthToken } from '../services/api';
import { getSubscriptionStatus, getTokenBalance, checkTwitchOauthStatus  } from '../redux/actions/authActions';
import defaultPicture from '../assets/icons/mrferret.png';
import ContactSection from '../containers/components/ContactSection';
import './styles/accountPage.css';

const AccountPage = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const isPaidSubscriber = useSelector((state) => state.auth.isPaidSubscriber);
  const tokenBalance = useSelector((state) => state.auth.tokenBalance);
  const reduxTwitchUsername = useSelector(state => state.auth.twitch_username);

  // Get the tab from query params or default to 'overview'
  const queryParams = new URLSearchParams(pageLocation.search);
  const initialTab = queryParams.get('tab') || 'overview';
  const [activeTab, setActiveTab] = useState(initialTab);



  useEffect(() => {
    if (isAuthenticated) {
      const fetchAuthData = async () => {
        try {
          const token = await getAccessTokenSilently();
          setAuthToken(token);
          dispatch(getSubscriptionStatus());
          dispatch(getTokenBalance());
          dispatch(checkTwitchOauthStatus());
        } catch (error) {
          console.error("Error fetching Auth0 token:", error);
          navigate('/login');
        }
      };
      fetchAuthData();
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch, navigate]);

  const renderComponent = () => {
    switch (activeTab) {
      case 'profile':
        return (
          <Profile
            tokenBalance={tokenBalance}
            isPaidSubscriber={isPaidSubscriber}
            user={{
              ...user,
              picture: user?.picture || defaultPicture,}}
              twitchname={reduxTwitchUsername}
          />
        );
      case 'subscription':
        return <Subscription tokenBalance={tokenBalance} isPaidSubscriber={isPaidSubscriber} />;
      case 'help':
        return <Help />;
      default:
        return (
          <Overview
            tokenBalance={tokenBalance}
            isPaidSubscriber={isPaidSubscriber}
            user={{
              ...user,
              picture: user?.picture || defaultPicture,}}
              twitchname={reduxTwitchUsername}
          />
        );
    }
  };

  return (
    <div className="account-page-container">
      <NavBar />
      <div className="account-page-content-wrapper">
        <div className="account-sidebar">
          <ul className="sidebar-menu">
            <li
              className={`sidebar-item ${activeTab === 'overview' ? 'active' : ''}`}
              onClick={() => setActiveTab('overview')}
            >
              Overview
            </li>
            <li
              className={`sidebar-item ${activeTab === 'profile' ? 'active' : ''}`}
              onClick={() => setActiveTab('profile')}
            >
              Profile
            </li>
            <li
              className={`sidebar-item ${activeTab === 'subscription' ? 'active' : ''}`}
              onClick={() => setActiveTab('subscription')}
            >
              Subscription
            </li>
            <li
              className={`sidebar-item ${activeTab === 'help' ? 'active' : ''}`}
              onClick={() => setActiveTab('help')}
            >
              Contact
            </li>
          </ul>
        </div>
        <div className="account-page-content">
          {renderComponent()}
        </div>
      </div>
      <div className="account-page-footer">
        <ContactSection />
      </div>
    </div>
  );
};

export default AccountPage;