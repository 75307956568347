// src/accountPage/components/Subscription.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import sessionService from '../../services/sessionService';
import { setAuthToken } from '../../services/api';
import { FaCheckCircle } from 'react-icons/fa';
import { getSubscriptionStatus } from '../../redux/actions/authActions';
import '../styles/Subscription.css';

// Load Stripe with your publishable key
const stripePromise = loadStripe('pk_live_51QFI6yIBRgBxJCQK0SvuVju7PYusEg4m6pr06gkDEfPnJs9Y0GAIF2MFHhYc7qlTv9aMuo6jxv0Qz3ASVbL4ldPc00cNt2cwTl');

const Subscription = () => {
   const { getAccessTokenSilently} = useAuth0();
    const dispatch = useDispatch();
   const [subscription, setSubscription] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [error, setError] = useState(null);
   const isPaidSubscriber = useSelector((state) => state.auth.isPaidSubscriber);

  // Set the token for API requests when the component loads
  useEffect(() => {
    const setTokenSilently = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAuthToken(token);
        const subscriptionData = await sessionService.fetchStripeSubscription();
        setSubscription(subscriptionData.subscription);
        dispatch(getSubscriptionStatus());
      } catch (error) {
        // console.error('Error fetching token or Stripe Subscription', error);
        setError('Failed to fetch subscription data.');
      } finally {
        setIsLoading(false);
      }
    };

    setTokenSilently();
  }, [getAccessTokenSilently, dispatch]);

  

  // Handle subscribe button click
  const handleSubscribe = async () => {
    try {
      const stripe = await stripePromise;

      // Call the function to create a checkout session
      const session = await sessionService.createCheckoutSession();

      // Redirect to Stripe checkout
      if (session?.sessionId) {
        const result = await stripe.redirectToCheckout({ sessionId: session.sessionId });
        if (result.error) {
          // console.error('Stripe error:', result.error.message);
        }
      } else {
        // console.error('Failed to create a checkout session');
      }
    } catch (error) {
      // console.error('Error in handleSubscribe:', error);
    }
  };

  // Handle manage subscription button click
  const handleManageSubscription = async () => {
    try {
      // Call the function to get the customer portal URL
      const portalSession = await sessionService.createCustomerPortalSession();

      // Redirect to the customer portal
      if (portalSession?.url) {
        window.location.href = portalSession.url;
      } else {
        // console.error('Failed to create a customer portal session');
      }
    } catch (error) {
      // // console.error('Error in handleManageSubscription:', error);
    }
  };

  return (
      <div className="subscription-container">
        {isPaidSubscriber ? (
          isLoading ? (
            <p>Loading subscription details...</p>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : (
            <div className="subscription-details">
              <h3 className="subscription-status-title">Subscription Status</h3>
              <div className="status-indicator">
                <FaCheckCircle className="icon-subscribed" />
                <span className="active-text">Active Subscription</span>
              </div>
              {subscription && (
                <div className="subscription-info-wrapper">
                  <div className="subscription-info-item">
                    <p className="subscription-label">Plan:</p>
                    <p className="subscription-value">{subscription.plan?.nickname || 'Basic Subscription'}</p>
                  </div>
                  <div className="subscription-info-item">
                    <p className="subscription-label">Status:</p>
                    <p className="subscription-value">{subscription.status || 'Active'}</p>
                  </div>
                  <div className="subscription-info-item">
                    <p className="subscription-label">Next Billing Date:</p>
                    <p className="subscription-value">{new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                  </div>
                </div>
              )}
              <div className="manage-subscription-container">
                <button className="manage-button" onClick={handleManageSubscription}>
                  Manage Subscription
                </button>
              </div>
            </div>
          )
        ) : (
          <div className="subscription-not-subscribed">
            <div className="subscribe-basic-container">
              <div className="subscribe-features">
                <p className="highlight-text"><strong> AI services</strong></p>      
                <p><span className="highlight-number">600,000</span> tokens. Approx <span className="highlight-number">20 hours</span> of <strong>LIVE!</strong> at default settings. Save up to <span className="highlight-number">10</span> <strong>Create!</strong> ChatSessions</p>
                
              </div>
              <div className="custom-price-sub">
              <p>$9.99</p>
                </div>
              <button className="btn btn-primary mt-3 subscribe-button" onClick={handleSubscribe}>
                Subscribe Now
              </button>
              <div className="subscribe-info">
                <p>Payments and subscriptions are securely handled by Stripe</p>
                <p>Re-occuring • Renews once a month • Cancel anytime</p>
              </div>
            </div>
          </div>
        )}
      </div>
  );
};

export default Subscription;