// src/accountPage/components/Overview.js
import React from 'react';
import { FaCoins, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import '../styles/Overview.css';

const Overview = ({ tokenBalance, isPaidSubscriber, user, twitchname }) => {
  return (
    <div className="overview-container">
      <h2 className="overview-title">Account Overview</h2>
      <div className="overview-section">
      <div className="overview-item-profile">
        <div className="profile-header">
          <h3>Profile</h3>
        </div>
        <div className="profile-content">
          <div className="profile-login">
            <h3 className="item-secondary-title-profile">Login</h3>
            <img
              src={user.picture}
              alt="User Profile"
              className="profile-picture"
              onError={(e) => (e.target.src = 'path/to/default/image.png')}
            />
            <p>{user.name || 'User'}</p>
          </div>
          <div className="profile-connections">
            <h3 className="item-secondary-title-profile">Live Page Connections</h3>
            <div className="connection-item">
            <span className="connection-label">Twitch: </span>
            {twitchname ? (
              <div className="connection-status connected">
                <FaCheckCircle className="icon-connected" />
                <span className="twitchname-text">{twitchname}</span>
              </div>
            ) : (
              <div className="connection-status not-connected">
                <FaTimesCircle className="icon-not-connected" />
                <span>No twitch connection</span>
              </div>
            )}
          </div>
          </div>
        </div>
      </div>
        <div className="overview-item">
          <div className="item-title">
            <h3>Subscription Status</h3>
          </div>
          <div className="subscription-status">
            {isPaidSubscriber ? (
              <div className="status-indicator">
                <FaCheckCircle className="icon-subscribed" />
                <span>Paid Subscriber</span>
              </div>
            ) : (
              <div className="status-indicator">
                <FaTimesCircle className="icon-not-subscribed" />
                <span>Not Subscribed</span>
              </div>
            )}
          </div>
          <div className="token-balance">
            <FaCoins className="token-icon" />
            <span>{tokenBalance}  tokens</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
