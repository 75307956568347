// src/accountPage/components/Help.js
import React from 'react';

const Help = () => {
  return (
    <div className="about-section-container">
      <h2 className="about-section-title">Contact</h2>
      <div className="about-section-content">
        <p>
          For questions, suggestions, or support, please reach out to me at:
        </p>
        <ul className="about-section-list">
          <li>Email: <strong>mrferretgames@gmail.com</strong></li>
          
        </ul>
      </div>
    </div>
  );
};

export default Help;
